import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "withdrawal-pwd__title"
};
import { ref, watch } from 'vue';
export default {
  __name: 'PayPassword',
  props: ['showPassword'],
  emits: ['submit', 'close'],
  setup(__props, {
    emit: __emit
  }) {
    const emit = __emit;
    const props = __props;
    const pwd = ref('');
    const internalShowPassword = ref(props.showPassword);

    // 监听 props 的变化，更新内部的状态
    watch(() => props.showPassword, newValue => {
      internalShowPassword.value = newValue;
    });
    const _Confirm = () => {
      emit('submit', pwd.value);
    };
    watch(internalShowPassword, newValue => {
      if (newValue) {
        pwd.value = '';
      }
    });
    return (_ctx, _cache) => {
      const _component_van_icon = _resolveComponent("van-icon");
      const _component_van_password_input = _resolveComponent("van-password-input");
      const _component_van_number_keyboard = _resolveComponent("van-number-keyboard");
      const _component_van_popup = _resolveComponent("van-popup");
      return _openBlock(), _createBlock(_component_van_popup, {
        show: internalShowPassword.value,
        "onUpdate:show": _cache[2] || (_cache[2] = $event => internalShowPassword.value = $event),
        position: "bottom",
        onClose: _cache[3] || (_cache[3] = $event => emit('close'))
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_van_icon, {
          name: "arrow-left",
          onClick: _cache[0] || (_cache[0] = $event => emit('close'))
        }), _cache[4] || (_cache[4] = _createElementVNode("span", null, "WITHDRAWAL PASSWORD", -1))]), _createVNode(_component_van_password_input, {
          value: pwd.value,
          length: "6"
        }, null, 8, ["value"]), _createElementVNode("div", {
          class: "withdrawal-amount__confirm",
          onClick: _Confirm
        }, "Confirm"), _createVNode(_component_van_number_keyboard, {
          style: {
            "position": "relative"
          },
          modelValue: pwd.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => pwd.value = $event),
          "random-key-order": "",
          show: ""
        }, null, 8, ["modelValue"])]),
        _: 1
      }, 8, ["show"]);
    };
  }
};